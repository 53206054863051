import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { graphql, Link } from "gatsby";
import noimageImg from "../../images/noimage.png"



const TagPage = ({ data }) => (
  <Layout>
    <SEO title={data.microcmsTag.tagname} />

    <Row>
      <Col style={{ maxWidth: `60rem`, marginTop: `2rem` }}>
        <h1>
          {`#` + data.microcmsTag.tagname}
        </h1>
      </Col>
    </Row>
    <Row style={{ maxWidth: `60rem` }}>
      {data.allMicrocmsRecipe.edges.map(({ node }) => (
        <Col xs={12} md={4} key={node.recipeId}>
          <Card style={{ marginTop: `1rem` }}>
            <Card.Header>
              <Link to={`/recipe/${node.recipeId}`}>
                <h5>{node.title}</h5>
              </Link>
            </Card.Header>
            <Card.Body >
              <Link to={`/recipe/${node.recipeId}`}>
                <Card.Img src={node.image === null ? noimageImg : node.image.url + `?fit=crop&w=1000&h=1000`} alt={node.title} />
              </Link>
              <Card.Text>
                {node.tags.map(tags => (
                  <Link to={`/tag/${tags.id}`} style={{ margin: `0.5rem` }} key={tags.id}>
                    {`#` + tags.tagname}
                  </Link>
                ))}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    <Row>
      <Col style={{ maxWidth: `60rem`, paddingTop: `3rem` }}></Col>
    </Row>
  </Layout>
);

export default TagPage;

export const query = graphql`
query ($tagId: String!)  {
    allMicrocmsRecipe(filter: {tags: {elemMatch: {id: {eq: $tagId}}}}, sort: {order: DESC, fields: createdAt}) {
        edges {
          node {
            title
            id
            recipeId
            createdAt
            tags {
              tagname
              id
            }
            image {
              url
            }
          }
        }
      }
      microcmsTag(tagId: {eq: $tagId}) {
        tagname
      }
    }
`